import Cookies from 'universal-cookie';
import { isEqual } from 'lodash';
export const updatePopularSearchesCookie = (textSuggestions: any): any => {
    const cookies = new Cookies();
    let finalSuggestions = textSuggestions;
    if (finalSuggestions?.length > 0) {
        if (!isEqual(finalSuggestions, cookies?.get('popularSearches'))) {
            const date = new Date();
            date.setDate(date.getDate() + 1);
            cookies.set(`popularSearches`, finalSuggestions, { path: '/', expires: date, secure: true });
        }
        return finalSuggestions;
    } else {
        finalSuggestions = cookies.get('popularSearches');
        return finalSuggestions;
    }
};