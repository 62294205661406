import React from 'react';
import { RatingComponent } from '@msdyn365-commerce/components';
import { ICoreContext, Image } from '@msdyn365-commerce/core';
import { getFallbackImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import { removeSpecialChars } from '../../../../Utilities/allRegex';

const _buildImageUrl = (baseImgUrl: string, imgUrl: string) => {
  if (imgUrl) {
    return `${baseImgUrl}${imgUrl}`;
  }
  return "";
};

const SearchResultProduct = React.memo((props: any) => {
  const {
    productName,
    baseUrl,
    basePrice,
    AdjustedPrice,
    CustomerContextualPrice,
    context,
    uniqueId,
    AverageRatingOverall,
    AverageRatingCountOverall,
    recordId,
    defaultVariantId
  } = props;

  const buildURL = (baseUrl: string, productName: string, recordId: number) => {
    const filteredName = removeSpecialChars(productName, context);
    const lowercaseProduct = filteredName.toLowerCase().split(' ').join('-');
    return `${baseUrl}/${lowercaseProduct}/${recordId}.p${defaultVariantId ? `?variantid=${defaultVariantId}` : ''}`;
  };

  const renderRating = (
    context: ICoreContext,
    typeName: string,
    id: string,
    avgRating?: number,
    totalRatings?: number
  ): JSX.Element | null => {
    const numRatings = (totalRatings && totalRatings.toString()) || '0';
    if (avgRating || avgRating === 0) {
      // const ratingAriaLabel = getRatingAriaLabel(avgRating, ariaLabel);
      return (
        <RatingComponent
          context={context}
          id={id}
          typeName={typeName}
          avgRating={Math.round(avgRating * 10) / 10}
          ratingCount={numRatings}
          readOnly={true}
          ariaLabel={""}
          data={{}}
        />
      );
    } else {
      return (
        <RatingComponent
          context={context}
          id={id}
          typeName={typeName}
          avgRating={0}
          ratingCount={numRatings}
          readOnly={true}
          ariaLabel={""}
          data={{}}
        />
      );
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    props.onClick();
  };

  const emptyPlaceHolderImage = props.context.app.config.placeholderImageName as string;
  let fallbackImage = getFallbackImageUrl(props.uniqueId, props.context.request.apiSettings);

  if (emptyPlaceHolderImage && fallbackImage) {
    fallbackImage = `${fallbackImage},${emptyPlaceHolderImage}`;
  }

  return (
    <a className="card-link" href={buildURL(baseUrl, productName, recordId)} onClick={handleClick}>
      <div className="autosuggest-card">
        <div className="autosuggest-product-image-container">
          <Image
            className="autosuggest-product-image"
            src={_buildImageUrl(props.baseImgUrl, props.imgUrl)}
            fallBackSrc={fallbackImage}
            gridSettings={context.request.gridSettings!}
            bypassHideOnFailure={true}
            aria-hidden={true}
            role='img'
          />
        </div>
        <div className="autosuggest-product-info">
          <p className="selectable-card__product-name">
            {`${props.brandName && props.brandName.length > 0 ? props.brandName : ''} ${props.productName}`}
          </p>
          {
            basePrice ? (
              <div className="rec-product-price">
                {
                  AdjustedPrice && CustomerContextualPrice && CustomerContextualPrice < AdjustedPrice
                    ? <>
                      <span className="rec-current-price">${CustomerContextualPrice}</span>
                      <span className="product-original">
                        <span className="rec-standard-price crossed-out">
                          <span className="product-dollars">${AdjustedPrice}</span>
                        </span>
                      </span>
                    </>
                    : <span className="product-original price-text">
                      <span className="product-dollars">${basePrice}</span>
                    </span>
                }
              </div>
            ) : null
          }
          {
            renderRating(
              context,
              "mfrm-autosuggest",
              uniqueId,
              AverageRatingOverall,
              AverageRatingCountOverall
            )
          }
        </div>
      </div>
    </a>
  );
});

export default SearchResultProduct;
