import React from 'react';

const SearchResultItem = React.memo((props: any) => {
  const { productName } = props;
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    props.onClick(productName);
  };
  const handleKeyDown = (e: {key: string}) => {
    if (e.key === 'Enter') {
      props.onClick(productName);
    }
  };
  return (
    <div className="search-result-item autosuggest-link" onClick={handleClick} onKeyDown={handleKeyDown}>
      <p tabIndex={0}>
        {props.productName}
      </p>
    </div>
  );
});

export default SearchResultItem;
