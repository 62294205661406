import React from 'react';
import dayjs from 'dayjs';

const SociStoresItem = (props: any) => {
    const { address1, address2, city, _distance, name, project_meta, postalcode, state, website_url, bho } = props.location;
    const storeHours = JSON.parse(bho)?.[dayjs().day()];
    const opensAt = dayjs(`1/1/1 ${storeHours[0].slice(0, 2)}:${storeHours[0].slice(2)}`).format('hh:mm A');
    const closedAt = dayjs(`1/1/1 ${storeHours[1].slice(0, 2)}:${storeHours[1].slice(2)}`).format('hh:mm A');
    const isStoreOpen = (storeHours[0].slice(0, 2) < dayjs().hour() && storeHours[1].slice(0, 2) > dayjs().hour()) ? true : false;
    const isFranchise = JSON.parse(project_meta)?.Division === 'Franchise' || false;
    const projectMetaSoci = props.location ? JSON.parse(project_meta) : '';
    const sociStore = projectMetaSoci ? projectMetaSoci['Corporate ID'] : '';
    const sociStoreID = sociStore && (sociStore?.length > 6 ? sociStore : `000000${sociStore}`?.slice(-6)) || '';
    const isPreferredStore = props.preferredStore ? props.preferredStore === sociStoreID : false;

    const handleTealium = () => {
        // @ts-ignore
        if ('utag' in window) {
            const pageURL = window.location.pathname;
            // @ts-ignore
            utag.link({
                event_category: "store locator slideout",
                event_action: "search bar zip store details click",
                event_label: `from: ${pageURL}`,
                event_noninteraction: "false"
            });
        }
    };

    return (
        <div className='search-result-stores__list'>
            <div className={`search-result-stores__items ${isFranchise ? 'franchise' : ''}`}>
                <div className='search-result-stores__items-title'>
                    <span>{name}</span>
                    {isFranchise ? <span className='franchise-pill'>Franchise</span> : <></>}
                </div>
                <div className='search-result-stores__items__time-distance'>
                    {bho && <span className='search-result-stores__items__time-distance-time'>{isStoreOpen ? `Open until ${closedAt}` : `Open at ${opensAt}`}</span>}
                    {_distance && <span className='search-result-stores__items__time-distance-distance'> {_distance} {props?.resources?.autoSuggestStoreMiles ?? 'mi'}</span>}
                </div>
                <div className='search-result-stores__items-address'>
                    <p>{address1}</p>
                    <p>{address2}</p>
                    <p>{city}, {state}
                        <span className={isPreferredStore && !isFranchise ? 'search-result-stores__items-address-zipcode-bold' : 'search-result-stores__items-address-zipcode'}>{postalcode}</span>
                    </p>
                </div>
                <div className='search-result-stores__items-mystore-link'>
                    {isPreferredStore && !isFranchise && <div className='search-result-stores__items-mystore'>{props?.resources?.autoSuggestMyStore ?? 'My Store'}</div>}
                    <a className='search-result-stores__items-link' href={website_url} onClick={() => handleTealium()} target='_blank'>{props?.resources?.autoSuggestStoreDetails ?? 'Store Details'}</a>
                </div>
            </div>
        </div>
    );
};
export default SociStoresItem;