import React from 'react';
import SearchResultItem from '../search-result-item/search-result-item';
import SearchResultProduct from '../search-result-product/search-result-product';
import partialRight from 'lodash/partialRight';

enum CategoryTitleHeader {
  'Suggestions' = 'suggestions-category-header',
  'Popular Searches' = 'suggestions-category-header',
  'Recent Searches' = 'suggestions-category-header',
}

enum SearchSuggestionsProducts {
  'Suggestions' = 'search-result__suggestions',
  'Popular Searches' = 'search-result__suggestions',
  'Recent Searches' = 'search-result__suggestions',
}
const _renderItemsByType = (suggestions: any) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>, suggestion: any) => {
    suggestions.onClickItem(suggestion);
    suggestions.setQuery(e, suggestion);
  };
  const handleKeyDown = (e: { key: string }, suggestion: any) => {
    if (e.key === 'Enter') {
      suggestions.onClickItem(suggestion);
      suggestions.setQuery(e, suggestion);
    }
  };
  if (suggestions && typeof suggestions?.data[0] === "string") {
    return suggestions.data.map((suggestion: string) => (
      <div
        key={suggestion}
        onClick={partialRight(handleClick, suggestion)}
        className="search-result-item autosuggest-link"
        onKeyDown={partialRight(handleKeyDown, suggestion)}
      >
        <p tabIndex={0}>{suggestion}</p>
      </div>
    ));
  }

  if (suggestions && suggestions.data[0]?.type === "text") {
    return suggestions.data.map(
      (textSuggestion: any) => (
        <SearchResultItem
          key={textSuggestion.productName}
          productName={textSuggestion.productName}
          searchQsp={suggestions.searchQsp}
          searchPageUrl={suggestions.searchPageUrl}
          baseUrl={textSuggestion.baseUrl}
          onClick={partialRight(handleClick, textSuggestion.productName)}
        />
      ));
  }
  if (suggestions.data && suggestions.data[0]?.type === "product") {
    return suggestions.data.map(
      (productSuggestion: any) => (
        <SearchResultProduct
          key={productSuggestion.uniqueId}
          productName={productSuggestion.productName}
          uniqueId={productSuggestion.uniqueId}
          searchQsp={suggestions.searchQsp}
          searchPageUrl={suggestions.searchPageUrl}
          baseUrl={productSuggestion.baseUrl}
          baseImgUrl={productSuggestion.baseImgUrl}
          imgUrl={productSuggestion.imgUrl}
          basePrice={productSuggestion.basePrice}
          AdjustedPrice={productSuggestion.AdjustedPrice}
          CustomerContextualPrice={productSuggestion.CustomerContextualPrice}
          context={suggestions.context}
          AverageRatingCountOverall={productSuggestion.AverageRatingCountOverall}
          AverageRatingOverall={productSuggestion.AverageRatingOverall}
          recordId={productSuggestion.recordId}
          defaultVariantId={productSuggestion.defaultVariantId}
          brandName={productSuggestion.brandName}
          onClick={partialRight(suggestions.onClickProduct, productSuggestion)}
        />
      ));
  }
};

const SearchResultCategory = React.memo((props: any) => {
  const items = _renderItemsByType(props);
  return (
    <div className={`${SearchSuggestionsProducts[props.categoryTitle] ?? 'search-result__products'}`}>
      <div className={`${CategoryTitleHeader[props.categoryTitle] ?? 'products-category-header'}`}>
        {props.categoryTitle}
      </div>
      {
        props.data && props.data.length > 0 && items
      }
    </div>
  );
});

export default SearchResultCategory;
