import React from 'react';
import { getUrlSync } from '@msdyn365-commerce/core-internal';
import MsDyn365, { ICoreContext } from '@msdyn365-commerce/core';
import SearchResultCategory from '../search-result-category';
import partialRight from 'lodash/partialRight';
import SociStoresComponent from '../search-result-stores';
import { updatePopularSearchesCookie } from '../../../../Utilities/set-cookies';

const _getKeywordSuggestions = (suggestions: any, context: ICoreContext) =>
	suggestions.response?.products?.length > 0 && suggestions.response?.products?.map((suggestion: any) => {
		const params = MsDyn365.isBrowser && window.location;
		const origin = params && params.origin === 'https://localhost:4000' ? params.origin : context.request.url.requestUrl.origin;
		if (suggestion.doctype === "KEYWORD_SUGGESTION" || suggestion.doctype === "TOP_SEARCH_QUERIES") {
			const keywordSuggestion = {
				keyword: suggestion['autosuggest'],
				productName: suggestion['autosuggest'],
				baseUrl: origin,
				doctype: suggestion.doctype,
				baseImgUrl: context.request.apiSettings.baseImageUrl,
				type: "text"
			};
			return keywordSuggestion;
		}
		return null;
	}).filter((rec: any) => rec);

const _getProductSuggestions = (suggestions: any, context: ICoreContext) =>
	suggestions.response?.products?.length > 0 && suggestions.response?.products?.map((suggestion: any) => {
		const params = MsDyn365.isBrowser && window.location;
		const origin = params && params.origin === 'https://localhost:4000' ? params.origin : context.request.url.requestUrl.origin;
		if (suggestion.doctype === "POPULAR_PRODUCTS") {
			const productSuggestion = {
				keyword: suggestion['autosuggest'],
				productName: suggestion['ProductName'], // product name
				baseUrl: origin,
				imgUrl: suggestion["PrimaryImageUrl"] ? suggestion["PrimaryImageUrl"] : null,
				doctype: suggestion.doctype,
				baseImgUrl: context.request.apiSettings.baseImageUrl,
				AdjustedPrice: suggestion.lowest_variant_adjustedPrice,
				CustomerContextualPrice: suggestion.CustomerContextualPrice,
				basePrice: suggestion.BasePrice,
				context: context,
				uniqueId: suggestion.uniqueId,
				type: "product",
				AverageRatingOverall: suggestion.AverageRatingOverall,
				AverageRatingCountOverall: suggestion.AverageRatingCountOverall,
				defaultVariantId: suggestion.Unbxd_DefaultVariantID,
				recordId: suggestion.RecordId,
				brandName: suggestion["Attr_5637145355"] || "", // brand name
				retailVariantId: suggestion?.Unbxd_DefaultVariantRetailVariantId ?? ""
			};
			return productSuggestion;
		}
		return null;
	}).filter((rec: any) => rec);

const SearchResultContainer = React.memo((props: any) => {
	const { suggestedSociLocations, preferredStore } = props;
	let textSuggestions = _getKeywordSuggestions(props.data, props.context) || [];
	if (props.isSearchBarEmpty) {
		textSuggestions = updatePopularSearchesCookie(textSuggestions);
	}
	const productSuggestions = _getProductSuggestions(props.data, props.context) || [];
	const searchQsp = (
		props.context && props.context.app
		&& props.context.app.config &&
		props.context.app.config.searchQueryStringParameter
	) || '';

	const keywordSearchPageURL = getUrlSync(
		'/search',
		props.context && props.context.actionContext
	) || '';
	return (
		<div className='search-result-container'>
			{(props.recentlySearched && props.recentlySearched.length > 0 || textSuggestions && textSuggestions.length > 0) ? <div className='search-result-container-keywords'>
				{
					props.recentlySearched && props.recentlySearched.length > 0
						? <SearchResultCategory
							data={props.recentlySearched}
							context={props.context}
							searchQsp={searchQsp}
							searchPageUrl={keywordSearchPageURL}
							queryParams={props.queryParams}
							categoryTitle="Recent Searches"
							onClickItem={partialRight(props.onClickItem, "Recent Searches")}
							onSubmit={props.onSubmit}
							setQuery={props.setQuery}
						/>
						: null
				}
				{
					textSuggestions && textSuggestions.length > 0
						?
						<SearchResultCategory
							data={textSuggestions}
							searchQsp={searchQsp}
							searchPageUrl={keywordSearchPageURL}
							queryParams={props.queryParams}
							categoryTitle={props.isSearchBarEmpty ? "Popular Searches" : "Suggestions"}
							onClickItem={partialRight(props.onClickItem, props.isSearchBarEmpty ? "Popular Searches" : "Suggestions")}
							onSubmit={props.onSubmit}
							setQuery={props.setQuery}
						/>
						: null
				}
			</div> : null}
			{
				productSuggestions && productSuggestions.length > 0
					? <SearchResultCategory
						data={productSuggestions}
						context={props.context}
						searchQsp={searchQsp}
						searchPageUrl={keywordSearchPageURL}
						queryParams={props.queryParams}
						categoryTitle={props.isSearchBarEmpty ? "Popular Products" : "Products"}
						onClickProduct={props.onClickProduct}
					/>
					: null
			}
			{suggestedSociLocations && suggestedSociLocations.length > 0 && <SociStoresComponent data={suggestedSociLocations} preferredStore={preferredStore?.StoreId} resources={props?.resources} />}
			{
				productSuggestions.length === 0 && textSuggestions.length === 0 && suggestedSociLocations.length === 0
					? <div>No results found.</div>
					: null
			}
		</div>
	);
});

export default SearchResultContainer;