import React from 'react';
import SociStoresItems from '../search-result-store-item';

const SociStoresComponent = React.memo((props: any) => {
    return (
        <div className='search-result-stores'>
            <div className='search-result-stores-header'>
               {props.resources?.autoSuggestStoreHeading ?? 'Stores'}
            </div>
            {props.data.map((location: any, i: number) => <SociStoresItems key={i} location={location} preferredStore={props.preferredStore} resources={props.resources}/>)}
        </div>

    );
});

export default SociStoresComponent;